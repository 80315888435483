<template>
  <div class="content">
    <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
      <el-form-item label="兑换类型：">
        <span>优惠券</span>
      </el-form-item>
      <el-form-item label="活动商品标题：" prop="product_name">
        <el-input v-model="edit.product_name" placeholder="请输入商品标题"></el-input>
      </el-form-item>
      <el-form-item label="积分兑换需：" prop="integral">
        <div class="flex">
          <el-input v-model="edit.integral" type="number" placeholder="请输入商品兑换积分"></el-input>
          <span style="padding-left: 16px;">天</span>
        </div>
      </el-form-item>
      <el-form-item label="库存数量：" prop="stock">
        <el-input v-model="edit.stock" type="number" placeholder="请输入库存数量"></el-input>
      </el-form-item>
      <el-form-item label="起止日期：" prop="date">
        <el-date-picker
          v-model="edit.date"
          type="daterange"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="排序号：">
        <el-input v-model="edit.sort" placeholder="请输入排序"></el-input>
        <span class="tips">排序号越小，排序位置越前</span>
      </el-form-item>
      <div class="link">
        <p class="link-title">关联优惠券</p>
        <div class="link-btn">
          <el-button class="tb-button" type="primary" @click="addCoupon">
            <i class="tb-icon-plus"/>关联优惠券
          </el-button>
        </div>
        <el-table
          :data="edit.coupon"
          class="tb-table"
        >
          <el-table-column prop="couponName" label="优惠券名称" />
          <el-table-column label="操作">
            <span @click="deleteCoupon" class="edit">删除</span>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item label="部署状态：">
        <el-radio-group v-model="edit.status">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="submit">
      <el-button type="primary" @click="onConfirm">确定</el-button>
      <el-button @click="onCancel">取消</el-button>
    </div>
    <el-dialog
      title="选择优惠券"
      width="50vw"
      :visible.sync="couponVisible"
    >
      <div style="display: flex; align-items: center; margin-bottom: 24px;">
        <span>搜索优惠券：</span>
        <el-input v-model="couponname" style="width: 216px;"></el-input>
      </div>
      <el-table
        ref="multipleTable"
        :data="couponList"
        class="tb-table"
        height="48vh"
        @selection-change="selectionChange"
        @row-click="selectionSingleChange"
      >
        <el-table-column type="selection" width="80" />
        <el-table-column prop="couponName" label="优惠券名称" />
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="coupon.no + 1"
        :page-size="coupon.size"
        :total="coupon.total"
        @current-change="couponChange"
      />
      <span slot="footer">
        <el-button @click="couponVisible = false">取 消</el-button>
        <el-button type="primary" @click="couponConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'IntrgralAdd',
  data() {
    return {
      id: null,
      edit: {
        product_name: '',
        integral: '',
        product_type: 1,
        stock: '',
        sort: '',
        status: 1,
        date: null,
        coupon: []
      },
      rules: {
        product_name: [{required: true, message: '请输入名称'}],
        integral: [{required: true, message: '请输入积分'}],
        stock: [{required: true, message: '请输入库存'}],
        date: [{required: true, message: '请选择日期'}]
      },
      couponChoose: [],
      coupon: {
        no: 0,
        size: 10,
        total: 0
      },
      couponname: '',
      couponList: [],
      couponVisible: false
    }
  },
  mounted() {
    this.id = this.$route.query.id * 1 || null
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id
      }
      const url = 'admin/integral/product/detail'
      const data = await this.$https.get(url, {params})
      if (!data) return
      this.edit.product_name = data.product_name
      this.edit.product_type = data.product_type
      this.edit.integral = data.integral
      this.edit.date = [this.$dayjs(data.start_time).format('YYYY-MM-DD'), this.$dayjs(data.end_time).format('YYYY-MM-DD')]
      this.edit.status = data.status
      this.edit.sort = data.sort
      this.edit.stock = data.stock
      this.edit.coupon = [{
        couponName: data.coupon_name,
        id: data.external_id
      }]
    },
    async getCouponList() {
      const {no, size} = this.coupon
      const params = {
        page_size: size,
        page_no: no,
        name: this.couponname,
        status: 1
      }
      const url = 'admin/coupon/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      this.coupon.total = data.total_count
      this.couponList = data.list
    },
    addCoupon() {
      this.couponVisible = true
      this.getCouponList()
    },
    selectionChange(choose) {
      if (choose.length > 1) {
        const ele = choose.pop()
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(ele)
        this.couponChoose = ele
      } else {
        this.couponChoose = choose[0]
      }
    },
    selectionSingleChange(choose) {
      this.couponChoose = choose
      this.$refs.multipleTable.clearSelection()
      this.$refs.multipleTable.toggleRowSelection(choose)
    },
    couponChange(no) {
      this.coupon.no = no - 1
      this.getCouponList()
    },
    couponConfirm() {
      if (!this.couponChoose.id) {
        this.$message.error('请选择优惠券')
        return
      }
      this.couponname = ''
      this.choose = null
      this.couponList = []
      this.edit.coupon.push(this.couponChoose)
      this.couponVisible = false
    },
    deleteCoupon() {
      this.$confirm('是否删除此优惠券?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.edit.coupon = []
      }).catch(() => {})
    },
    async onConfirm () {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      if (this.edit.coupon.length === 0) {
        this.$message.error('请选择需要关联的优惠券')
        return
      }
      const params = {
        product_name: this.edit.product_name,
        product_type: this.edit.product_type,
        integral: this.edit.integral,
        external_id: this.edit.coupon[0].id,
        start_time: this.edit.date[0],
        end_time: this.edit.date[1],
        status: this.edit.status,
        sort: this.edit.sort,
        stock: this.edit.stock,
      }
      if (this.id) params.id = this.id
      const url = 'admin/integral/product/edit'
      this.$https.post(url, params).then(res => {
        if (res) {
          this.$message.success(this.id ? '编辑成功' : '添加成功')
          this.onCancel()
        }
      })
    },
    onCancel() {
      this.$router.push('/integral/list')
    }
  }
}
</script>

<style scoped lang="scss">
.tb-form {
  width: 586px;
  margin: 0 auto;
  .tips {
    padding-top: 8px;
    color: rgba(0, 0, 0, 0.45);
  }
  .link {
    margin-bottom: 32px;
    &-title {
      margin: 0 0 24px 0;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    &-btn {
      margin-bottom: 16px;
    }
  }
}
.submit {
  text-align: center;
}
</style>
